import { toast } from "sonner";
import Button from "./ui/button/Button";
import { useCart } from "../context/CartContext";

export default function Person({ name, img, id }) {
  const { addToCart } = useCart();

  function onPersonClick(e) {
    e.preventDefault();
    addToCart(id);
    toast.success(`${name} say thanks for Tip ;)`)
  }

  return (
    <div className="item">
      <div className="img">
        {/* <a href="/details"> */}
          <img src={img} alt="" />
        {/* </a> */}
      </div>
      <div className="info">
        <h6>{name}</h6>
        {/* <p>Architect</p> */}
        <div className="social valign">
          <div className="full-width">
            <Button handler={onPersonClick}>Give Tip</Button>
            {/* <p>dipl. Arch FH</p>
            <a href="#"><i className="ti-facebook"></i></a>
            <a href="#"><i className="ti-twitter-alt"></i></a>
            <a href="#"><i className="ti-instagram"></i></a> */}
          </div>
        </div>
      </div>
    </div>
  )
}