import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom"
import { toast } from "sonner";
import { useAuth } from "../../context/AuthContext"
import AccountAmount from "../../widgets/account-amount/AccountAmount";
import CartTotalCost from "../../widgets/cart-total-cost/CartTotalCost";
import CartList from "../../widgets/cart-list/CartList";
import Button from '../ui/button/Button';
import './Header.css';
import logo from '../../assets/img/logo.png';

export default function Header() {
  const [isVisible, setVisible] = useState(false);
  const [isCartVisible, setCartVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuth, logout } = useAuth();

  function toggleMenu(e) {
    e.preventDefault();
    setVisible(!isVisible);
  }

  function toggleCart(e) {
    e.preventDefault();
    setCartVisible(!isCartVisible);
  }

  async function handleLogout(e) {
    e.preventDefault();
    try {
      await logout()
      navigate("/login");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleCheckout(e) {
    e.preventDefault();
    toast.success('Purches');
  }

  React.useEffect(() => {
    setVisible(false);
    setCartVisible(false);
  }, [location]);

  return (
    <>
      <header className="bauen-header">
        <div className="container">
          <div className="row bauen-header-inner">
            <div className="bauen-logo-wrap d-flex align-items-center justify-content-center">
              <a
                href="/#"
                className={isVisible ? 'bauen-logo active' : 'bauen-logo'}
                onClick={toggleMenu}
              >
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="text-right bauen-wrap-burger-wrap">
              <a href="/#"
                className={isCartVisible ? "bauen-nav-toggle cart-toogle active" : "bauen-nav-toggle cart-toogle hide"}
                onClick={toggleCart}
              ><i></i></a>
              <CartTotalCost isVisible={isCartVisible} clickCart={toggleCart} />
              <a href="/#"
                className={isVisible ? "bauen-nav-toggle active" : "bauen-nav-toggle"}
                onClick={toggleMenu}
              ><i></i></a>
            </div>
          </div>
        </div>
      </header>
      <section className={isVisible ? "bauen-wrap bauen-wrap-show" : "bauen-wrap"}>
        <div className="bauen-wrap-inner">
          <nav className="bauen-menu">
            <ul>
              <li>
                <AccountAmount />
              </li>
              {
                isAuth &&
                <>
                  <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                </>
              }
              {
                !isAuth &&
                <>
                  <li><NavLink to="/">About</NavLink></li>
                  <li><NavLink to="/login">Login</NavLink></li>
                  <li><NavLink to="/signup">Create Account</NavLink></li>
                </>
              }
            </ul>
          </nav>
          <div className="bauen-menu-footer">
            {
              isAuth &&
              <li>
                <button className="btn btn-secondary" onClick={handleLogout}>Logout</button>
              </li>
            }
            <div className="separator"></div>
            <p>p : +66833470255</p>
            <p>@ : support@bandits.bar</p>
          </div>
        </div>
      </section>
      <section className={isCartVisible ? "cart_list bauen-wrap bauen-wrap-show" : "cart_list bauen-wrap"}>
        <div className="bauen-wrap-inner">
          <nav className="bauen-menu">
            <CartList></CartList>
          </nav>
          <div className="bauen-menu-footer">
            {
              isAuth &&
              <li>
                <Button handler={handleCheckout}>Checkout</Button>
              </li>
            }
            <div className="separator"></div>
            <p>@ : support@bandits.bar</p>
          </div>
        </div>
      </section>

    </>
  )
}
