import { useCart } from "../../context/CartContext";
import trashBacket from '../../assets/img/icons/trash.png';

export default function CartListItem({ id, name, img, quantity, price }) {
  const { addToCart, removeItemFromCart, deleteFromCart } = useCart();

  const handleDecrease = (e) => {
    e.preventDefault();
    return removeItemFromCart(id);
  }

  const handleIncrease = (e) => {
    e.preventDefault();
    return addToCart(id);
  }

  const handleRemove = (e) => {
    e.preventDefault();
    return deleteFromCart(id);
  }

  return (
    <>
      <div className="cart_list_widget-img">
        <img className="" src={img} alt={name} />
      </div>
      <div className="cart_list_widget-details">
        <p>Beer for {name} </p>
        <p>Quantity: {quantity} <span className="cart_list_widget-control">
          <button
            className="cart_list_widget-increase"
            onClick={handleIncrease}
          >+</button>
          /
          <button
            className="cart_list_widget-decrease"
            onClick={handleDecrease}
          >-</button>
        </span></p>
        <p>Price: {price * quantity} $
          <button
            className="cart_list_widget-remove"
            onClick={handleRemove}
          >
            <img src={trashBacket} alt=""/>
          </button>
        </p>
      </div>
    </>
  )
}