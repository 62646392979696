import { useEffect } from 'react';
import about from '../assets/img/media/about.jpg';
import { useAuth } from "../context/AuthContext"
import { Link, useNavigate } from "react-router-dom";

export default function About() {
  const { isAuth } = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuth) {
      navigate('/dashboard')
    }
  }, [isAuth])

  return (
    <section className="about section-padding2">
      <div className="container">
        <div className="row">

          <div className="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
            <h2 className="section-title">About <span>Bandits</span></h2>
            <p>To watch and interact with our girls online, please register below</p>
            <Link className='ui_button' to="/signup">Sign Up</Link> / <Link className='ui_button' to="/login">Login</Link>
            {/* <p>Design inilla duiman at elit finibus viverra nec a lacus themo the drudea seneoice misuscipit non sagie the fermen.</p> */}
            {/* <p>Planner inilla duiman at elit finibus viverra nec a lacus themo the drudea seneoice misuscipit non sagie the fermen.</p> */}
          </div>
          <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
            <div className="about-img">
              <div className="img">
                <img src={about} className="img-fluid" alt="" />
              </div>
              <div className="about-img-2 about-buro">Bandits</div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}