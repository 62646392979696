const data = [
  {
    id: '1',
    name: 'Diana',
    price: 25,
    img: '/assets/img/team/5.jpg'
  },
  {
    id: '2',
    name: 'Anastasia',
    price: 25,
    img: '/assets/img/team/2.jpg'
  },
  {
    id: '3',
    name: 'Elisabet',
    price: 25,
    img: '/assets/img/team/6.jpg'
  },
  {
    id: '4',
    name: 'Beata',
    price: 25,
    img: '/assets/img/team/4.jpg'
  },
]

export async function getServices() {
  return data;
}

export async function getServiceById(id){
  return data.find(item => item.id === id);
}

export  function getServicesByIds(ids){
  return data.filter(item => ids.includes(item.id));
}