import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { toast } from "sonner";
import { getServices } from "../services/Services";
import Person from './Person';

export default function Services() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices()
      .then((services) => setServices(services))
      .catch(error => toast.error(error.me))
    setServices(services);
  }, [services]);

  return (
    <section className="team">
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="section-title">Our <span>Girls</span></h2>
          </Col>
        </Row>
        <Row>
          {services.map(person => (
            <Col sm={3} key={person.id}>
              <Person {...person} />
            </Col>
          ))}
        </Row>
      </Container>
    </section >
  )
}