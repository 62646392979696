import { Container } from "react-bootstrap";
import Services from "../components/Services";


export default function Dashboard() {
  return (
    <>
      <Container>
        <Services />
      </Container>
    </>
  )
}
