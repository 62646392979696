import { createContext, useContext, useState } from "react";

import { getServicesByIds } from "../services/Services";

export const CartContext = createContext({
  items: [],
  getQuantity: () => { },
  increaseQuantity: () => { },
  addToCart: () => { },
  removeItemFromCart: () => { },
  deleteFromCart: () => { },
  getTotalCost: () => { },
});

export function CartProvider({ children }) {
  const [cartProducts, setCartProducts] = useState([]);

  function getCartItems() {
    const productIds = cartProducts.map(item => item.id);
    const items = getServicesByIds(productIds);
    const itemsWithQuantity = items.reduce((acc, item) => {
      const cartItem = cartProducts.find(cq => cq.id === item.id)
      acc.push({
        quantity: cartItem.quantity,
        ...item
      })
      return acc
    }, []);
    return itemsWithQuantity
  }

  function getQuantity(id) {
    return cartProducts.find(item => item.id === id)?.quantity ?? 0;
  }

  function addToCart(id) {
    const quantity = getQuantity(id);
    if (quantity === 0) {
      setCartProducts([
        ...cartProducts,
        {
          id: id,
          quantity: 1
        }
      ])
    } else {
      setCartProducts(cartProducts.map(item => {
        return (item.id === id)
          ? { ...item, quantity: item.quantity + 1 }
          : item
      }))
    }
  }

  function deleteFromCart(id) {
    setCartProducts(cartProducts.filter(item => item.id !== id))
  }

  function removeItemFromCart(id) {
    const quantity = getQuantity(id);
    if (quantity === 1) {
      return deleteFromCart(id);
    }
      setCartProducts(cartProducts.map(item => {
        return item.id === id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      }))
  }

  function getTotalCost() {
    let totalCost = 0;
    const productIds = cartProducts.map(item => item.id);
    const products = getServicesByIds(productIds);
    if (!products) {
      return 0
    }
    cartProducts.map(item => {
      const product = products.find(p => item.id === p.id);
      totalCost = totalCost + (item.quantity * product.price);
      return null
    });
    return totalCost;
  }

  const contextValue = {
    getCartItems,
    getQuantity,
    addToCart,
    removeItemFromCart,
    deleteFromCart,
    getTotalCost,
  }

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  )
}

export function useCart() {
  return useContext(CartContext);
}

export default CartProvider;