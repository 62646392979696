import { useEffect, useState } from "react";
import { useCart } from "../../context/CartContext";
import cartImg from '../../assets/img/icons/cart.png';
import './CartTotalCost.css';

export default function CartTotalCost({ clickCart, isVisible }) {
  const [total, setTotal] = useState(0);
  const { getTotalCost } = useCart();

  useEffect(() => {
    const total = getTotalCost();
    setTotal(total);
  }, [getTotalCost])

  return (
    <div className={isVisible || total === 0 ? "cart_widget hide" : 'cart_widget'} onClick={clickCart}>
      <div className="cart_widget-price">
        {total}
      </div>
      <div className="cart_widget-icon">
        <img src={cartImg} alt="" />
      </div>
    </div>
  )
}