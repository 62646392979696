import React, { useRef, useState } from "react";
import { Form, Button, Card, Container, Row } from "react-bootstrap";
import { toast } from 'sonner';
import GoogleButton from "react-google-button";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import './Auth.css';

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, googleSignIn } = useAuth()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate("/dashboard")
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false)
  }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();

    try {
      await googleSignIn();
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Container >
        <Row className="d-flex justify-content-center" >
          <Card className="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-card">
            <Card.Body >
              <h2 className="text-center mb-4">Log In</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
                <Button disabled={loading} className="w-100" type="submit">
                  Log In
                </Button>
              </Form>
              <hr />
              <div>
                <GoogleButton
                  className="g-btn"
                  type="light "
                  onClick={handleGoogleSignIn}
                />
              </div>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            Need an account? <Link to="/signup">Sign Up</Link>
          </div>
        </Row>
      </Container>
    </>
  )
}
