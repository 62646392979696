import { useCart } from "../../context/CartContext";
import CartListItem from './CartLlistItem';
import './CartList.css';

export default function CartList() {
  const { getCartItems, getTotalCost } = useCart()
  const total = getTotalCost();
  const items = getCartItems();

  return (
    <>
      <ul className="cart_list_widget">
        <li>
          <span className="cart_list_widget-total">Total cost: {total} $</span>
        </li>
        {items.map(item => (
          <li
            className="cart_list_widget-item"
            key={item.id}
          >
            <CartListItem {...item} />
          </li>
        ))}
      </ul>
    </>
  )
}