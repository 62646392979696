import { Routes, Route } from "react-router-dom";
import { Toaster } from 'sonner'
import ProtectedRoute from "./auth/ProtectedRoute";
import { AuthContext } from "../context/AuthContext";
import Banner from "./sections/Banner";
import Footer from "./sections/Footer";
import Lines from "./sections/Lines";
import Header from "./header/Header";
import About from "../pages/About";
import Login from './auth/Login';
import Signup from './auth/Signup';
import Dashboard from '../pages/Dashboard';
import Success from "../pages/Success";
import Cancel from '../pages/Cancel';
import CartProvider from "../context/CartContext";

function App() {
  return (
    <AuthContext>
      <Toaster />
      <div className="content-wrapper">
        <CartProvider>
          <Header />
          <Lines />
          <Banner />
          <Routes>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/success"
              element={
                <ProtectedRoute>
                  <Success />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cancel"
              element={
                <ProtectedRoute>
                  <Cancel />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
          <Footer />
        </CartProvider>
      </div>
    </AuthContext>
  );
}

export default App;
