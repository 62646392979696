export default function Footer() {
  return (
    <footer className="main-footer dark">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Phone</h6>
              </div>
              {/* <p>+66833470255</p> */}
              <p>+40751459592</p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Email</h6>
              </div>
              <p>support@bandits.bar</p>
            </div>
          </div>
          <div className="col-md-4 mb-30">
            {/* <div className="item fotcont">
              <div className="fothead">
                <h6>Our Address</h6>
              </div>
              <p>437 144 Pattaya Soi 6, Pattaya City, Bang Lamung District, Chon Buri 20150, Thailand</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="text-left">
                <p>© 2023 Dellirom. All right reserved.</p>
              </div>
            </div>
            <div className="col-md-4 abot">
              <div className="social-icon">
                <a href="https://www.facebook.com/BanditsHideout?mibextid=kFxxJD"><i className="ti-facebook"></i></a>
                <a href="/"><i className="ti-twitter"></i></a>
                <a href="/"><i className="ti-instagram"></i></a>
              </div>
            </div>
            <div className="col-md-4">
              <p className="right"><a href="/">Terms &amp; Conditions</a></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}