import wallet from '../../assets/img/icons/wallet.png';
import './AccountAmount.css';
import { useAuth } from '../../context/AuthContext';

export default function AccountAmount() {
  const { isAuth } = useAuth();
  return (
    <>
      <div className="account_amount">
        <img src={wallet} alt="Account amount icon" className="account_amount-icon" />
        <div className='account_amount-value'>
          {isAuth ? 100 : 0}
        </div>
      </div>
    </>
  )
}